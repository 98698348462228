.navbar {
    /*position: relative;*/
    /*float: left;*/
}

.espacamento {
    /*margin: 10px;*/
    /*padding: 10px;*/
    width: 100%;
    /*background-color: orangered;*/
    position: relative;
    float: left;
}

.tamanhoMenuItem {
    font-size: 20px !important;
}

.pi {
    font-size: 2rem !important;
}