.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
}

.minHeight {
    min-height: 70%;
}

.start {
    justify-content: flex-start;
}

.column {
    flex-direction: column;
    justify-content: flex-start;
}