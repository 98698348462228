.title {
    font-size: larger;
    font-weight: bolder;
    padding-bottom: 10px;
}

.pesquisa {
    width: 175px !important;
}

.espacamentoBotoes {
    margin-left: 2px;
    margin-right: 2px;
}