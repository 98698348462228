.componentePaiHorarioFuncionamento {
    width: 80%;
    padding: 10px;
    font-weight: bolder;
}

.componentesHorarioFuncionamento {
    margin-right: 10px;
    margin-bottom: 10px;
}

.botaoRemoverImagem {
    width: 250px;
    margin-left: 7px
}

.botaoRemoverImagemCardapio {
    width: 180px;
    margin-left: 7px
}

.botaoRemoverImagemAlinhamento {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}

.botaoRemoverImagemRestauranteAlinhamento {
    margin-right: 6px;
}

.cardImagemCardapio {
    height: 340px;
}

.menuFotos {
    width: 300px;
}

.textoExplicativo {
    font-size: 16px;
}