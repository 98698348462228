.espacamento {
    /*padding-top: 30px;*/
    width: 100% !important;
}

.label {
    font-size: 18px;
}

.totalPagar {
    font-size: 20px !important;
    font-weight: bold;
}

.formatacaoTextoPix {
    padding: 10px 10px 10px 5px !important;
    font-weight: bold;
}