.espacamento {
    /*padding-top: 30px;*/
    width: 100% !important;
}

.label {
    font-size: 18px;
    position: relative;
    float: left;
    /*margin-bottom: -20px;*/
    /*margin-bottom: 5px;*/
}

.tamanhoLabelLogin {
    width: 200px;
}

.espacamentoSwitchLogin {
    padding-left: 20px;
}

.espacamentoSwitchExplicacao {
    display: table-cell;
    vertical-align: middle;
}

.alinhamentoSwitch {
    display: table;
    height: 110px;
}
