.title {
    font-size: larger;
    font-weight: bolder;
    padding-bottom: 10px;
}

.espacamento {
    padding-bottom: 10px;
}

.espacamentoBotoes {
    margin-left: 2px;
    margin-right: 2px;
}