.linkLogin {
    color: #0e7490;
    font-weight: bold;
}

.divMasterLogin {
    width: 100%;
    height: 100%;
    background: rgb(3,106,124);
    background: linear-gradient(180deg, rgba(3,106,124,0.9248074229691877) 0%, rgba(3,106,124,0.5466561624649859) 30%, rgba(247,254,255,1) 100%);
}

.divMaster {
    width: 100%;
    min-height: 100vh;
    background: rgb(3,106,124);
    background: linear-gradient(to bottom, rgba(3,106,124,0.925) 0%, rgba(3,106,124,0.547) 30%, rgba(247,254,255,1) 100%) no-repeat center center;
    background-size: cover;
}

.formlogin {
    width: 350px;
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.55);
    border-radius: 10px;
}

.formcriarconta {
    width: 700px;
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.55);
    border-radius: 10px;
}

.espacamento {
    margin-bottom: 15px;
}

.centralizacaoVertical {
    top: 20%;
    position: relative;
}

.centralizar {
    display: flex;
    justify-content: center;
}

.sombra {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.tamanhoBotaoEntrar {
    width: 100%;
}
