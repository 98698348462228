.espacamento {
    /*padding-top: 5px;*/
    width: 100% !important;
}

.label {
    font-size: 18px;
}

.espacamentoBotaoCriarNovo {
    padding: 10px;
}

.posicaoBotaoExcluirGrupo {
    position: relative;
    float: right;
}