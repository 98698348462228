html, body, #root {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  background-color: #efefef;
  height: 100%;
  overflow: auto;
}

.p-toolbar {
  background-color: #fff;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: #eeeeee;
}

.p-megamenu {
  background-color: #fff;
}

.p-menuitem-text {
  color: black !important;
}


/**
section.planos span.obs-plano {margin-top:10px; display:block; color:#888;}
@media( min-width:575px ) {
    section.planos .card-plano .btn {position:absolute; bottom:64px; }
}
@media( max-width:990px ) {
    section.planos span.obs-plano {margin-left:114px;}
}
 */