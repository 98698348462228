.navbar {
    display: flex;
    justify-content: space-between;
    background-color: #036A7C;
    padding: 1em;
    color: floralwhite;
    font-weight: bolder;
    align-items: center;
    height: 100px;
    font-size: 24px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}